import testimonials from './testimonialData'

const testimonialCardParent = document.querySelector(
  '.swiper-wrapper.testimonial-cards',
)

testimonials.forEach((testimonial) => {
  const testimonialCardEl = document
    .querySelector('[data-element="testimonial-card-slider"]')
    .cloneNode(true)
  testimonialCardEl.querySelector(
    '[data-element="testimonial-people-name"]',
  ).textContent = testimonial.name
  testimonialCardEl.querySelector(
    '[data-element="testimonial-position"]',
  ).textContent = testimonial.position
  testimonialCardEl.querySelector(
    '[data-element="testimonial-text-all"]',
  ).innerHTML = testimonial.testimonyTextAll
  testimonialCardEl.querySelector(
    '[data-element="testimonial-text-less"]',
  ).innerHTML = testimonial.testimonyTextLess ?? testimonial.testimonyText
  testimonialCardEl.querySelector(
    '[data-element="testimonial-company-logo"]',
  ).src = testimonial.companyLogo
  testimonialCardEl
    .querySelector('[data-element="testimonial-company-logo"]')
    .parentElement.classList.add(testimonial.companyLogoClass)
  testimonialCardEl.querySelector(
    '[data-element="testimonial-photo-small"]',
  ).srcset = testimonial.photo.mobile
  testimonialCardEl.querySelector(
    '[data-element="testimonial-photo-large"]',
  ).src = testimonial.photo.desktop

  testimonialCardParent.appendChild(testimonialCardEl)
})
document
  .querySelectorAll('[data-element="testimonial-card-slider"]')[0]
  .remove()

document.querySelectorAll('.testimonial-content .showMoreBtn').forEach((el) => {
  el.addEventListener('click', () => {
    const testimonialContent = el.parentElement.parentElement
    testimonialContent.querySelector(
      '[data-element="testimonial-text-all"]',
    ).style.display = 'inline'
    testimonialContent.querySelector(
      '[data-element="testimonial-text-less"]',
    ).style.display = 'none'
  })
})
document.querySelectorAll('.testimonial-content .showLessBtn').forEach((el) => {
  el.addEventListener('click', () => {
    const testimonialContent = el.parentElement.parentElement
    testimonialContent.querySelector(
      '[data-element="testimonial-text-all"]',
    ).style.display = 'none'
    testimonialContent.querySelector(
      '[data-element="testimonial-text-less"]',
    ).style.display = 'inline'
  })
})
