const btnTopNavigationEls = document.querySelectorAll('.btn.link-item')
const navigationMegaDropdownEl = document.querySelector(
  '.navigationMegaDropdown',
)

const setValueDropDownNavigation = (keyElement) => {
  const dropdownNavigation = {
    services: [
      {
        pageUrl: 'intelligent-finance.html',
        logoSrc: new URL(
          '../icon/battery-charging.png?as=webp',
          import.meta.url,
        ),
        title: 'Intelligent Finance Function',
      },
      {
        pageUrl: 'manage-payroll.html',
        logoSrc: new URL('../icon/settings-02.png?as=webp', import.meta.url),
        title: 'Managed Payroll',
      },
      {
        pageUrl: 'consulting.html',
        logoSrc: new URL('../icon/life-buoy.png?as=webp', import.meta.url),
        title: 'Consulting',
      },
    ],
    products: [
      {
        pageUrl: 'people-management.html',
        logoSrc: new URL('../icon/people.png?as=webp', import.meta.url),
        title: 'People Management',
      },
      {
        pageUrl: 'power-insight.html',
        logoSrc: new URL('../icon/grid.png?as=webp', import.meta.url),
        title: 'Power Insight',
      },
      {
        pageUrl: 'all-product.html',
        logoSrc: new URL('../icon/box.png?as=webp', import.meta.url),
        title: 'All Product',
      },
    ],
  }

  navigationMegaDropdownEl
    .querySelectorAll('.navigationMegaDropdownBtn')
    .forEach((el, index) => index > 0 && el.remove())

  dropdownNavigation[keyElement].forEach((value) => {
    const navigationMegaDropdownBtn = navigationMegaDropdownEl
      .querySelector('.navigationMegaDropdownBtn')
      .cloneNode(true)
    navigationMegaDropdownBtn.href = value.pageUrl
    navigationMegaDropdownBtn.querySelector(
      '[data-element="logoNavigation"]',
    ).src = value.logoSrc
    navigationMegaDropdownBtn.querySelector(
      '[data-element="titleNavigation"]',
    ).textContent = value.title

    navigationMegaDropdownEl
      .querySelector('.navigationMegaDropdown--inner')
      .appendChild(navigationMegaDropdownBtn)
  })

  navigationMegaDropdownEl
    .querySelectorAll('.navigationMegaDropdownBtn')[0]
    .remove()
}

btnTopNavigationEls.forEach((el) => {
  el.addEventListener('mouseenter', () => {
    el.classList.add('active')
    navigationMegaDropdownEl.style.display = 'flex'
    setValueDropDownNavigation(el.getAttribute('data-element'))
  })
  el.addEventListener('mouseleave', () => {
    el.classList.remove('active')
    navigationMegaDropdownEl.style.display = 'none'
  })

  navigationMegaDropdownEl.addEventListener('mouseenter', () => {
    el.classList.add('active')
    navigationMegaDropdownEl.style.display = 'flex'
  })
  navigationMegaDropdownEl.addEventListener('mouseleave', () => {
    el.classList.remove('active')
    navigationMegaDropdownEl.style.display = 'none'
  })
})
