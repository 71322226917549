document.querySelector('.btn.btn-hamburger').addEventListener('click', (e) => {
  document.querySelector('.navigationDrawer').classList.remove('hide')
})
document
  .querySelector('.btn.navigationDrawer--closeBtn')
  .addEventListener('click', (e) => {
    document.querySelector('.navigationDrawer').classList.add('hide')
  })

document.querySelectorAll('[data-element="navItem-expander"]').forEach((el) => {
  el.querySelector('.navigationDrawer--navItemBtn').addEventListener(
    'click',
    (e) => {
      if (
        el
          .querySelector('.navigationDrawer--navItemExpanded')
          .classList.contains('hide')
      ) {
        el.querySelector('.navigationDrawer--navItemExpanded').classList.remove(
          'hide',
        )
        el.querySelector('.navigationDrawer--navItemBtnArrow').style.rotate =
          '180deg'
      } else {
        el.querySelector('.navigationDrawer--navItemExpanded').classList.add(
          'hide',
        )
        el.querySelector('.navigationDrawer--navItemBtnArrow').style.rotate =
          '0deg'
      }
    },
  )
})
