const preferencePrivacyPopupEl = document.querySelector(
  '.preferencePrivacy-popup',
)
const preferencePrivacyPopupDetailEl = document.querySelector(
  '.preferencePrivacy-popupDetail',
)

const isCookies =
  document.cookie
    .split(';')
    .filter((value) => value === '_cookies=guest')[0] === '_cookies=guest' ||
  document.cookie
    .split(';')
    .filter((value) => value === ' _cookies=guest')[0] === ' _cookies=guest'

if (isCookies) {
  preferencePrivacyPopupEl.classList.remove('show')
  // document.querySelector('.cookies-alert-popup').style.display = 'none'
} else {
  preferencePrivacyPopupEl.classList.add('show')
  // document.querySelector('.cookies-alert-popup').style.display = 'block'
}

// Cookies Button
document.querySelector('.btn-cookies').addEventListener('click', () => {
  preferencePrivacyPopupEl.classList.add('show')
  // document.querySelector('.cookies-alert-popup').style.display = 'block'
})
document.querySelector('.cookies-accept-btn').addEventListener('click', () => {
  // document.querySelector('.cookies-alert-popup').style.display = 'none'
  document.cookie = '_cookies=guest; expires=Thu, 18 Dec 2024 12:00:00 UTC'
})

preferencePrivacyPopupEl
  .querySelector('.btn.preferencePrivacy-popup--closeBtn')
  .addEventListener('click', () => {
    preferencePrivacyPopupEl.classList.remove('show')
  })
preferencePrivacyPopupEl
  .querySelector('.btn.btn--rejectAllCookies')
  .addEventListener('click', () => {
    preferencePrivacyPopupEl.classList.remove('show')
    preferencePrivacyPopupDetailEl.classList.add('show')
    // document.cookie = '_cookies=guest; expires=Thu, 18 Dec 2024 12:00:00 UTC'
  })
preferencePrivacyPopupEl
  .querySelector('.btn.btn--allowAllCookies')
  .addEventListener('click', () => {
    preferencePrivacyPopupEl.classList.remove('show')
    document.cookie = '_cookies=guest; expires=Thu, 18 Dec 2024 12:00:00 UTC'
  })

document
  .querySelector('.btn.cookies-learn-more-btn')
  .addEventListener('click', () => {
    preferencePrivacyPopupEl.classList.add('show')
    document.querySelector('.cookies-alert-popup').style.display = 'none'
  })

preferencePrivacyPopupDetailEl
  .querySelector('.btn.preferencePrivacy-popupDetail--closeBtn')
  .addEventListener('click', () => {
    preferencePrivacyPopupDetailEl.classList.remove('show')
  })
preferencePrivacyPopupDetailEl
  .querySelector('.btn.btn--rejectAllCookies')
  .addEventListener('click', () => {
    preferencePrivacyPopupDetailEl.classList.remove('show')
    document.cookie = '_cookies=guest; expires=Thu, 18 Dec 2024 12:00:00 UTC'
  })
preferencePrivacyPopupDetailEl
  .querySelector('.btn.btn--allowAllCookies')
  .addEventListener('click', () => {
    preferencePrivacyPopupDetailEl.classList.remove('show')
    document.cookie = '_cookies=guest; expires=Thu, 18 Dec 2024 12:00:00 UTC'
  })
preferencePrivacyPopupDetailEl
  .querySelector('.btn.btn--preferencePrivacy-popupDetailConfirmPreference')
  .addEventListener('click', () => {
    preferencePrivacyPopupDetailEl.classList.remove('show')
    document.cookie = '_cookies=guest; expires=Thu, 18 Dec 2024 12:00:00 UTC'
  })
